<template>
  <!-- 结算管理 - 催收单审核 -->
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">结算管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">催收单审核</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox">
            <div title="付款机构" class="searchboxItem ci-full">
              <span class="itemLabel">付款机构:</span>
              <el-input
                v-model="seachData.payerName"
                type="text"
                size="small"
                placeholder="请输入付款机构"
                clearable
              />
            </div>
            <div title="培训机构" class="searchboxItem ci-full">
              <span class="itemLabel">培训机构:</span>
              <el-select
                size="small"
                v-model="seachData.compId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
              >
                <el-option
                  v-for="item in CompanyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
            <div title="快递状态" class="searchboxItem ci-full">
              <span class="itemLabel">快递状态:</span>
              <el-select
                size="small"
                v-model="seachData.expressDeliveryState"
                clearable
                placeholder="请选择快递状态"
              >
                <el-option
                  v-for="item in expressDeliveryStateList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="催收函状态" class="searchboxItem ci-full">
              <span class="itemLabel" style="width: 6rem">催收函状态:</span>
              <el-select
                size="small"
                v-model="seachData.collectionLetterState"
                clearable
                placeholder="请选择催收函状态"
              >
                <el-option
                  v-for="item in collectionLetterStateList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div class="df">
              <el-button
                type="primary"
                size="small"
                class="bgc-bv"
                round
                @click="getData()"
                >查询</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
              ><el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                width="50"
              />
              <el-table-column
                label="培训机构"
                align="left"
                show-overflow-tooltip
                prop="compName"
                minWidth="180"
              />
              <el-table-column
                label="付款机构"
                align="left"
                show-overflow-tooltip
                prop="payerName"
                minWidth="180"
              />
              <el-table-column
                label="催收金额"
                align="left"
                show-overflow-tooltip
                prop="collectionAmount"
                minWidth="100"
              />
              <el-table-column
                label="收件人信息"
                align="left"
                show-overflow-tooltip
                prop="addresseeName"
                minWidth="110"
              />
              <el-table-column
                label="快递单号"
                align="left"
                show-overflow-tooltip
                prop="expressDeliveryNumber"
                minWidth="150"
              />
              <el-table-column
                label="快递状况"
                align="left"
                show-overflow-tooltip
                prop="expressDeliveryState"
                minWidth="100"
              >
                <template slot-scope="{ row }">
                  {{
                    $setDictionary(
                      "EXPRESS_DELIVERY_STATE",
                      row.expressDeliveryState
                    )
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="商务备注"
                align="left"
                show-overflow-tooltip
                prop="remark"
                minWidth="150"
              />
              <el-table-column
                label="催收函状态"
                align="left"
                show-overflow-tooltip
                prop="collectionLetterState"
                minWidth="120"
              >
                <template slot-scope="{ row }">
                  {{
                    $setDictionary(
                      "COLLECTION_LETTER_STATE",
                      row.collectionLetterState
                    )
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="生成日期"
                align="left"
                show-overflow-tooltip
                prop="createTime"
                minWidth="150"
              />
              <el-table-column
                label="经办人"
                align="left"
                show-overflow-tooltip
                prop="adminName"
                minWidth="100"
              />
              <el-table-column
                label="审核人"
                align="left"
                show-overflow-tooltip
                prop="reviewerName"
                minWidth="150"
              />
              <el-table-column
                label="审批人"
                align="left"
                show-overflow-tooltip
                prop="approverName"
                minWidth="150"
              />
              <el-table-column
                label="备注"
                align="left"
                show-overflow-tooltip
                prop="refuseRemark"
                minWidth="150"
              />
              <el-table-column
                label="操作"
                align="center"
                width="240"
                fixed="right"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="addWaitCollection(scope.row)"
                    >查看</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="expressage(scope.row)"
                    >快递</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="remark(scope.row)"
                    >备注</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="invalid(scope.row)"
                    :disabled="scope.row.collectionLetterState=='50'"
                    >作废</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="toUploadAttachments(scope.row)"
                    >上传附件</el-button
                  >
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <!-- 催收单详情 -->
    <el-dialog
      title="查看催收单"
      :visible.sync="dialogDataState"
      v-if="dialogDataState"
      :close-on-click-modal="false"
      width="600px"
      top="5%"
      center
    >
      <el-form
        ref="dialogForm"
        :model="dialogData"
        label-width="110px"
        size="small"
        disabled
      >
        <el-form-item
          label="付款机构名称"
          prop="payerName"
          :rules="[
            { required: true, message: '请输入付款机构名称', trigger: 'blur' },
          ]"
        >
          <el-input
            v-model="dialogData.payerName"
            type="text"
            size="small"
            placeholder="请输入付款机构名称"
          />
        </el-form-item>
        <el-form-item label="邮寄地址">
          <el-input
            v-model="dialogData.address"
            type="text"
            size="small"
            placeholder="请输入邮寄地址"
          />
        </el-form-item>
        <el-form-item label="收件人姓名">
          <el-input
            v-model="dialogData.addresseeName"
            type="text"
            size="small"
            placeholder="请输入收件人姓名"
          />
        </el-form-item>
        <el-form-item
          label="收件人电话"
          prop="addresseeMobile"
          :rules="[
            {
              pattern: /^1[3456789]\d{9}$/,
              message: '请输入正确电话',
              trigger: ['change', 'blur'],
            },
          ]"
        >
          <el-input
            v-model="dialogData.addresseeMobile"
            type="text"
            size="small"
            placeholder="请输入收件人电话"
          />
        </el-form-item>
        <el-form-item label="催款金额小写">
          <el-input
            v-model="dialogData.collectionAmount"
            type="text"
            size="small"
            placeholder="请输入催款金额小写"
          />
        </el-form-item>
        <el-form-item label="催款金额大写">
          <el-input
            v-model="dialogData.collectionAmountChinese"
            type="text"
            size="small"
            placeholder="请输入催款金额大写"
          />
        </el-form-item>
        <el-form-item label="审核人">
          <el-select
            size="small"
            v-model="dialogData.reviewerId"
            placeholder="请选择审核人"
          >
            <el-option
              v-for="item in reviewerList"
              :key="item.adminId"
              :label="item.fullname"
              :value="item.adminId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审批人">
          <el-select
            size="small"
            v-model="dialogData.approverId"
            placeholder="请选择审批人"
          >
            <el-option
              v-for="item in approverList"
              :key="item.adminId"
              :label="item.fullname"
              :value="item.adminId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="附件信息">
          <template v-if="dialogData.fileList.length>0">
            <div v-for="(item,index) in dialogData.fileList">
              <el-button 
                :key="item.fileKey"
                :disabled="false"
                @click="lookPdforImg(item,index)"
                type="text"
              >
                {{item.fileName}}
                <el-image
                style="width: 0; height: 0" :ref="'img'+index"
                :src="item.fileUrl"
                :preview-src-list="[item.fileUrl]">
                </el-image>
              </el-button>
            </div>
          </template>
          <template v-else>
            无
          </template>
        </el-form-item>
        <el-form-item label-width="0">
          <el-table
            :data="dialogData.billList"
            size="mini"
            height="150px"
            stripe
            style="width: 100%"
          >
            <el-table-column label="序号" align="center" type="index" width="50px" />
            <el-table-column prop="billNo" label="结算单号">
            </el-table-column>
            <el-table-column prop="payerAmount" label="结算金额" width="110">
            </el-table-column>
            <el-table-column
              prop="settlementAmount"
              label="应收金额"
              width="110"
            >
            </el-table-column>
            <el-table-column
              prop="arrearsAmount"
              label="欠款金额"
              width="110">
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 备注信息 -->
    <el-dialog
      title="备注信息"
      :visible.sync="rejectData.rejecDialogVisible"
      width="500px"
      top="10%"
      :before-close="()=>{rejectData = $options.data().rejectData}"
      center
      >
      <el-form
        :model="rejectData"
        ref="rejectData"
        label-width="100px"
        class="demo-ruleForm"
        size="small"
      >
        <el-form-item
          label="备注信息"
          :rules="[
            { required: true, message: '请输入备注信息', trigger: 'blur' },
          ]"
          prop="remark"
        >
          <el-input type="textarea" :rows="7" maxlength="200" resize="none" v-model="rejectData.remark" placeholder="请输入备注信息"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="bgc-bv" @click="rejectData = $options.data().rejectData">取 消</el-button>
        <el-button class="bgc-bv" type="primary" @click="okSettleAccounts"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 快递详情 -->
    <el-dialog
      title="快递情况"
      :visible.sync="expressageDataState"
      v-if="expressageDataState"
      :close-on-click-modal="false"
      width="600px"
      top="5%"
      center
    >
      <el-form
        ref="expressageForm"
        :model="expressageData"
        label-width="140px"
        size="small"
      >
        <el-form-item
          label="付款机构名称"
          prop="payerName"
        >
          {{expressageData.payerName}}
        </el-form-item>
        <el-form-item label="邮寄地址">
          <el-input
            v-model="expressageData.address"
            type="text"
            size="small"
            placeholder="请输入邮寄地址"
          />
        </el-form-item>
        <el-form-item label="收件人姓名">
          <el-input
            v-model="expressageData.addresseeName"
            type="text"
            size="small"
            placeholder="请输入收件人姓名"
          />
        </el-form-item>
        <el-form-item
          label="收件人电话"
          prop="addresseeMobile"
          :rules="[
            {
              pattern: /^1[3456789]\d{9}$/,
              message: '请输入正确电话',
              trigger: ['change', 'blur'],
            },
          ]"
        >
          <el-input
            v-model="expressageData.addresseeMobile"
            type="text"
            size="small"
            placeholder="请输入收件人电话"
          />
        </el-form-item>
        <el-form-item label="快递单号">
          <el-input
            v-model="expressageData.expressDeliveryNumber"
            type="text"
            size="small"
            placeholder="请输入快递单号"
          />
        </el-form-item>
        <el-form-item label="发送日期">
          <el-date-picker
                v-model="expressageData.sendDate"
                value-format="yyyy-MM-dd"
                type="date"
                size="small"
                placeholder="请选择发送日期">
            </el-date-picker>
        </el-form-item>
        <el-form-item label="快递状态">
          <el-select
            size="small"
            clearable
            v-model="expressageData.expressDeliveryState"
            placeholder="请选择快递状态"
          >
            <el-option
              v-for="item in expressDeliveryStateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="签收日期或拒收日期">
          <el-date-picker
                v-model="expressageData.signingDate"
                value-format="yyyy-MM-dd"
                type="date"
                size="small"
                placeholder="请选择签收日期或拒收日期">
            </el-date-picker>
        </el-form-item>
        <el-form-item label="上传附件">
            <el-upload
                ref="fileupload"
                class="upload-demo upload-workers"
                action
                accept=".png,.jpg,.jpeg,.pdf"
                :on-change="handleChange1"
                :auto-upload="false"
                :show-file-list="false"
                :limit="10"
            >
                <el-button class="bgc-bv" slot="trigger" size="mini">选择文件</el-button>
            </el-upload>
        </el-form-item>
        <el-form-item
            v-for="(item,index) in expressageData.fileList"
            :key="index"
        >
            <div class="fle">
                <div style="word-break:break-all">{{item.fileName}}</div>
                <div style="min-width: 80px;text-align:right">
                    <el-image
                        style="width: 0; height: 0" :ref="'img1-'+index"
                        v-if="item.fileUrl"
                        :src="item.fileUrl"
                        :preview-src-list="[item.fileUrl]">
                    </el-image>
                    <el-button @click="lookPdforImg(item,'1-'+index)" type="text" size="mini">查看</el-button>
                    <el-button @click="delFile1(index)" type="text" size="mini">删除</el-button>
                </div>
            </div>
        </el-form-item>
        <el-form-item label-width="0">
            <span style="color:red;">请上传.png,.jpg,.jpeg,.pdf,.PDF等格式的文件，且最多10个文件！</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="expressageDataState = false">取 消</el-button>
        <el-button type="primary" @click="submitExpressage">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 上传附件 -->
    <el-dialog
      title="上传附件"
      :visible.sync="attachmentData.attachmentDataState"
      v-if="attachmentData.attachmentDataState"
      width="500px"
      :close-on-click-modal="false"
      :before-close="()=>{attachmentData = $options.data().attachmentData}"
      center
      >
      <el-form :model="attachmentData" label-width="5em">
        <el-form-item label="上传附件">
            <el-upload
                ref="fileupload"
                class="upload-demo upload-workers"
                action
                accept=".png,.jpg,.jpeg,.pdf"
                :on-change="handleChange"
                :auto-upload="false"
                :show-file-list="false"
                :limit="10"
            >
                <el-button class="bgc-bv" slot="trigger" size="mini">选择文件</el-button>
            </el-upload>
        </el-form-item>
        <el-form-item
            v-for="(item,index) in attachmentData.fileList"
            :key="index"
        >
            <div class="fle">
                <div style="word-break:break-all">{{item.fileName}}</div>
                <div style="min-width: 80px;text-align:right">
                    <el-image
                        style="width: 0; height: 0" :ref="'img2-'+index"
                        v-if="item.fileUrl"
                        :src="item.fileUrl"
                        :preview-src-list="[item.fileUrl]">
                    </el-image>
                    <el-button @click="lookPdforImg(item,'2-'+index)" type="text" size="mini">查看</el-button>
                    <el-button @click="delFile(index)" type="text" size="mini">删除</el-button>
                </div>
            </div>
        </el-form-item>
        <el-form-item label-width="0">
            <span style="color:red;">请上传.png,.jpg,.jpeg,.pdf,.PDF等格式的文件，且最多10个文件！</span>
        </el-form-item>
      </el-form>
        
      <span slot="footer" class="dialog-footer">
        <el-button class="bgc-bv" @click="attachmentData = $options.data().attachmentData">取 消</el-button>
        <el-button class="bgc-bv" type="primary" @click="submitAttachmentData"
          >保 存</el-button
        >
      </span>
    </el-dialog>
     <!-- 查看附件详情 - loading -->
    <el-dialog
      title="预览"
      :visible.sync="previewLoding1"
      v-if="previewLoding1"
      width="50%"
      :close-on-click-modal="false"
      top="2%"
      center
      v-dialogDrag
      append-to-body
    >
      <div style="height: 600px;">
        <div
          id="pdf-cert"
          style="height: 600px"
          v-if="fileType == 'pdf' || fileType == 'PDF'"
        ></div>
      </div>
    </el-dialog>
  </div>
</template>
  <script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import pdf from "pdfobject";
export default {
  name: "collectionList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据集
      seachData: {
        payerName: "", // 付款机构
        compId: "", // 机构id
        expressDeliveryState: "", // 快递状态
        collectionLetterState: "", // 催收函状态
      },
      expressDeliveryStateList: [], // 快递状态
      collectionLetterStateList: [], // 催收函状态
      CompanyList: [], // 机构list
      // 查看催收单弹窗数据
      dialogDataState: false,
      reviewerList: [], //审核人列表
      approverList: [], //审批人列表
      // 查看催收单
      dialogData: {
        payerName: "", // 付款机构名称
        address: "", // 邮寄地址
        addresseeName: "", // 收件人姓名
        addresseeMobile: "", // 收件人电话
        collectionAmount: "", // 催款金额小写
        collectionAmountChinese: "", // 催款金额大写
        billList: [], //结算单list
        fileList: [], // 文件列表
        reviewerId: "", // 审核人
        approverId: "", // 审批人
      },
      // 备注数据
      rejectData: {
        rejecDialogVisible: false, // 备注弹框
        collectionLetterId: "", // 催收单id
        remark: "", // 备注
      },
      // 快递弹窗数据
      expressageDataState: false,
      expressageData: {
        address: "", // 邮寄地址
        addresseeName: "", // 收件人姓名
        addresseeMobile: "", // 收件人电话
        expressDeliveryNumber: "", // 快递单号
        sendDate: "", // 发送日期
        expressDeliveryState: "", // 快递状态
        signingDate: "", // 签收日期或拒收日期
        collectionLetterId: "", // 催收单id
        fileList: [], // 文件列表
      },
      // 上传附件
      attachmentData: {
        attachmentDataState: false,
        collectionLetterId: "", // 催收单id
        fileList: [], // 文件列表
      },
      previewLoding1:false,//预览
      fileType: "",
    };
  },
  created() {
    this.getDictionary();
    this.getReviewer();
    this.getApprover();
  },
  mounted() {},
  computed: {},
  methods: {
    // 获取字典
    getDictionary() {
      // 快递状态
      let arr = this.$setDictionary("EXPRESS_DELIVERY_STATE", "list");
      for (const key in arr) {
        this.expressDeliveryStateList.push({
          label: arr[key],
          value: key,
        });
      }
      // 催收函状态
      let arr1 = this.$setDictionary("COLLECTION_LETTER_STATE", "list");
      for (const key in arr1) {
        this.collectionLetterStateList.push({
          label: arr1[key],
          value: key,
        });
      }
    },
    // 机构名称
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            console.log(res);
            if (res.status == 0) {
              this.CompanyList = res.data || [];
              console.log(this.CompanyList);
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    // 初始化获取列表
    getData(pageNum = 1) {
      const params = {
        // 当前页数
        pageNum: pageNum,
        // 每页条数
        pageSize: this.pageSize,
      };
      // 付款机构
      if (this.seachData.payerName) {
        params.payerName = this.seachData.payerName;
      }
      // 机构id
      if (this.seachData.compId) {
        params.compId = this.seachData.compId;
      }
      // 快递状态
      if (this.seachData.expressDeliveryState) {
        params.expressDeliveryState = this.seachData.expressDeliveryState;
      }
      // 催收函状态
      if (this.seachData.collectionLetterState) {
        params.collectionLetterState = this.seachData.collectionLetterState;
      }
      this.doFetch({
        url: "/biz/business/collectionLetterBill/pageList",
        params,
        pageNum,
      });
    },
    // 查看催收单
    addWaitCollection(row) {
      this.$post("/biz/business/collectionLetterBill/getInfo", {
        collectionLetterId: row.collectionLetterId,
      })
        .then((res) => {
          this.dialogData = Object.assign(this.dialogData, res.data); //赋值
          this.dialogDataState = true;
        })
        .catch(() => {
          return;
        });
    },
    // 弹窗 获取审核人列表
    getReviewer() {
      this.$post("/biz/collectionLetter/getReviewer", {}).then((res) => {
        this.reviewerList = res.data || [];
      });
    },
    // 弹窗 获取审批人列表
    getApprover() {
      this.$post("/biz/collectionLetter/getApprover", {}).then((res) => {
        this.approverList = res.data || [];
      });
    },
    //查看附件
    lookPdforImg(item,index) {
      this.fileType = item.fileType;
      if (item.fileType == "pdf" || item.fileType == "PDF") {
        this.previewLoding1 = true;
        setTimeout(() => {
          pdf.embed(item.fileUrl, "#pdf-cert");
        }, 300);
      } else {
        this.$refs['img'+index][0].showViewer = true;
      }
    },
    // 备注
    remark(row) {
        this.rejectData.collectionLetterId = row.collectionLetterId;
        this.rejectData.remark = row.remark;
        this.rejectData.rejecDialogVisible = true;
    },
    // 备注确定
    okSettleAccounts() {
      this.$refs["rejectData"].validate((valid) => {
        if (valid) {
          this.$post("/biz/business/collectionLetterBill/remark", {
            collectionLetterId: this.rejectData.collectionLetterId, // 催收单id
            remark: this.rejectData.remark // 备注
          }).then((res) => {
            this.$message.success("操作成功！");
            this.getData(-1);
            this.rejectData = this.$options.data().rejectData;
          });
        }
      });
    },
    // 作废
    invalid(row){
        this.$post("/biz/business/collectionLetterBill/invalid", {collectionLetterId:row.collectionLetterId}).then((res) => {
            this.$message.success("作废成功！");
            this.getData(-1);
        });
    },
    // 上传附件
    toUploadAttachments(row){
        this.attachmentData.collectionLetterId = row.collectionLetterId;
        this.$post("/biz/business/collectionLetterBill/fileInfo", {collectionLetterId:row.collectionLetterId}).then((res) => {
            this.attachmentData.fileList = res.data||[]
            this.attachmentData.attachmentDataState = true;
        });
    },
    // 上传附件
    handleChange(file,fileList) {
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isLt2M = file.size / 1024 / 1024 < 1;
      if(this.attachmentData.fileList.length==10){
        this.$message.error("不能上传了!");
        return false
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
        return false;
      } else if (
        !".png,.jpg,.jpeg,.pdf,.PDF".includes(
          extension[extension.length - 1]
        )
      ) {
        this.$message.warning({
          message: "请上传.png,.jpg,.jpeg,.pdf,.PDF等格式的文件！",
          duration: 1000,
        });
        return false;
      } else {
        let formData = new FormData();
        formData.append("folder ", "COURSE_WARE");
        formData.append("file ", file.raw);
        formData.append("fileType ", extension);
        this.$Postformat("/sys/upload", formData)
          .then((res) => {
            this.attachmentData.fileList.push({
              fileName:file.name,
              fileUrl:res.data.fileURL,
              fileKey:res.data.fileKey,
              fileType: extension,
            })
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    // 上传附件
    handleChange1(file,fileList) {
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isLt2M = file.size / 1024 / 1024 < 1;
      if(this.expressageData.fileList.length==10){
        this.$message.error("不能上传了!");
        return false
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
        return false;
      } else if (
        !".png,.jpg,.jpeg,.pdf,.PDF".includes(
          extension[extension.length - 1]
        )
      ) {
        this.$message.warning({
          message: "请上传.png,.jpg,.jpeg,.pdf,.PDF等格式的文件！",
          duration: 1000,
        });
        return false;
      } else {
        let formData = new FormData();
        formData.append("folder ", "COURSE_WARE");
        formData.append("file ", file.raw);
        formData.append("fileType ", extension);
        this.$Postformat("/sys/upload", formData)
          .then((res) => {
            this.expressageData.fileList.push({
              fileName:file.name,
              fileUrl:res.data.fileURL,
              fileKey:res.data.fileKey,
              fileType: extension,
            })
            console.log(this.expressageData);
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    // 删除附件
    delFile(index){
        this.attachmentData.fileList.splice(index,1)
    },
    // 删除附件
    delFile1(index){
        this.expressageData.fileList.splice(index,1)
    },
    // 上传附件 - 确认
    submitAttachmentData(){
        this.$post("/biz/business/collectionLetterBill/uploadFile", this.attachmentData)
          .then((res) => {
            if (res.status == 0) {
                this.$message.success('上传成功！')
                this.attachmentData = this.$options.data().attachmentData;
                this.getData(-1)
            }
          })
          .catch(() => {
            return;
          });
    },
    // 快递
    expressage(row){
        this.$post("/biz/business/collectionLetterBill/getExpressDelivery", {collectionLetterId:row.collectionLetterId})
          .then((res) => {
            if (res.status == 0) {
              Object.keys(this.expressageData).forEach(key=>{this.expressageData[key]=''})//清空
              this.expressageData = Object.assign(this.expressageData, res.data)//赋值
              this.expressageData.sendDate && (this.expressageData.sendDate = this.expressageData.sendDate.replaceAll("/","-"))
              this.expressageData.signingDate && (this.expressageData.signingDate = this.expressageData.signingDate.replaceAll("/","-"))
              this.expressageDataState = true
            }
          })
          .catch(() => {
            return;
          });
    },
    // 快递提交
    submitExpressage(row){
        this.$refs['expressageForm'].validate((valid) => {
            if (valid) {
                this.$post("/biz/business/collectionLetterBill/modify", this.expressageData)
                .then((res) => {
                    this.$message.success('修改快递信息成功！')
                    this.expressageDataState = false
                    this.expressageData = this.$options.data().expressageData;
                    this.getData(-1)
                })
            }
        });
        },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-dialog .el-table th{
  background: rgb(92, 107, 232);
  color: rgb(255, 255, 255);
  border-color: rgb(92, 107, 232);
}
.upload-workers {
  height: 40px;
  /deep/ .el-upload {
    height: 40px !important;
    width: 97px;
    border: none !important;  
    float: left;
    .el-button {
      padding: 5px 10px;
    }

  }
  /deep/ .el-upload-list {
      display: flex;
      flex-wrap: wrap;   /* 换行 */
      li {
        width: 20%;
      }
    }
}
.fle{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
  